<template>
  <div v-if="not_enrolled.length">
    <div
      class="row q-pt-lg"
      :class="{
        'justify-start': $q.platform.is.mobile,
        'justify-start': !$q.platform.is.mobile,
      }"
    >
      <div
        :key="`xs-${mission.id}`"
        class="col-12"
        v-for="mission in not_enrolled"
      >
        <public-item-component :tab="tab" :mission="mission" />
      </div>
    </div>
    <pagination-component
      :max="last_page"
      :current="current"
      @changeCurrent="current = $event"
    />
  </div>
  <div v-else class="q-mt-lg">
    <no-content-component />
  </div>
</template>

<script>
import PublicItemComponent from "./item-components/PublicItemComponent";
import PaginationComponent from "./PaginationComponent";
import noContentComponent from "./noContentComponent";
import { ref, watch } from "vue";

export default {
  name: "PublicNotEnrolledComponent",

  components: {
    noContentComponent,
    PaginationComponent,
    PublicItemComponent,
  },

  props: {
    tab: {
      type: String,
      required: true,
    },
    last_page: {
      type: Number,
      required: true,
    },
    not_enrolled: {
      type: Array,
      default: () => [],
    },
  },

  emits: ["changeCurrent"],

  setup(props, { emit }) {
    const current = ref(1);

    watch(current, (c) => emit("changeCurrent", c));

    return { current };
  },
};
</script>
