<template>
  <div class=" bg-white" :class="$q.platform.is.mobile ? 'q-pa-lg' : 'q-pa-md'">
    <div
      class="row"
      :class="{
        'justify-center': $q.platform.is.mobile,
        'justify-between': $q.platform.is.desktop,
      }"
    >
      <div
        :class="
          $route.name == 'public.missions'
            ? ''
            : $q.platform.is.mobile
            ? 'col-12 text-center'
            : ''
        "
      >
        <q-img
          :src="$route.name != 'public.missions' ? logo : '/flip/flip-icon.png'"
          :width="$route.name != 'public.missions' ? '105px' : '85px'"
          :class="
            $route.name != 'public.missions'
              ? $q.platform.is.mobile
                ? 'q-mt-lg '
                : 'q-mt-sm'
              : ''
          "
        />
      </div>
      <template v-if="$q.platform.is.mobile">
        <div>
          <q-btn
            v-if="$route.name == 'public.missions'"
            unelevated
            color="dark"
            icon="language"
            class="default-rounded q-ml-xl q-mt-lg"
            :label="$t('missions.return_site')"
            @click="redirect()"
          />
        </div>
        <q-tabs
          dense
          no-caps
          align="left"
          v-model="tab"
          narrow-indicator
          class="q-mt-md"
          @update:model-value="onTabChange"
        >
          <q-tab
            :name="tab"
            :ripple="false"
            :label="$t('missions.' + tab)"
            v-for="(tab, i) in ['notEnrolled', 'finished']"
            :key="i"
          />
        </q-tabs>
      </template>

      <template v-else>
        <div class="q-mt-lg">
          <q-tabs
            dense
            no-caps
            align="left"
            v-model="tab"
            narrow-indicator
            @update:model-value="onTabChange"
          >
            <q-tab
              :name="tab"
              :ripple="false"
              :label="$t('missions.' + tab)"
              v-for="(tab, i) in ['notEnrolled', 'finished']"
              :key="i"
            />
          </q-tabs>
        </div>
        <div class="q-mt-lg">
          <q-btn
            unelevated
            color="dark"
            icon="language"
            class="default-rounded"
            v-if="$route.name == 'public.missions'"
            :label="$t('missions.return_site')"
            @click="redirect()"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";

export default {
  name: "PublicHeaderComponent",

  props: {
    logo: {
      type: String,
      required: true,
    },
  },

  emits: ["tabChange"],

  setup(props, { emit }) {
    const tab = ref("notEnrolled");

    const redirect = () => window.open("https://flip.net.br/", "_blank");

    watch(tab, (current) => emit("tabChange", current));

    return {
      tab,
      redirect,
    };
  },
};
</script>
