<template>
  <div
    class="q-mt-md default-shadow default-card-border q-pa-md default-rounded"
    :class="tab == 'finished' ? 'bg-grey-3' : 'bg-white'"
  >
    <div class="row">
      <q-img
        class="q-mb-sm mobile-logo"
        v-if="$q.platform.is.mobile"
        :src="mission.partner.logo_foto_link"
      />
    </div>
    <div class="row">
      <div class="col-12 col-md-3">
        <q-img :src="getImage()" position="cover" :ratio="16 / 9" />
        <q-card-action class="row">
          <q-btn
            dense
            unelevated
            color="dark"
            :disabled="tab == 'finished'"
            :label="$t('missions.show_more_mission')"
            @click.prevent="redirect(mission.slug)"
            class="default-rounded text-white full-width q-mt-sm"
          />
        </q-card-action>
      </div>
      <div class="col-12 col-md-4">
        <basic-info-component :mission="mission" />
        <manage-component :data="mission" />
      </div>
      <div
        class="col-12 col-md-5"
        :class="$q.platform.is.desktop ? 'separator' : ''"
      >
        <div class="row q-mb-md q-mt-md">
          <div class="col-md-3">
            <div
              class="card-title text-weight-bolder text-uppercase text-justify"
              v-html="$t('missions.description')"
            />
          </div>
          <q-space />
          <div>
            <q-img
              style="width: 60px; margin-top: -10px"
              v-if="$q.platform.is.desktop"
              :src="mission.partner.logo_foto_link"
            />
          </div>
        </div>
        <div class="text-justify card-data">
          <span v-if="mission.short_description">
            <q-item-label class="card-data" lines="3">
              <span v-html="mission.short_description" />
            </q-item-label>
            <a
              href="#"
              target="_blank"
              class="text-weight-bolder link-color"
              v-if="mission.short_description.length > 200"
              @click.prevent="
                redirect(
                  tab == 'notEnrolled' ? mission.slug : mission.mission.slug
                )
              "
              >{{ $t("missions.show_more") }}
            </a>
          </span>
          <div
            v-else
            class="card-data"
            v-html="$t('missions.not_description')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import BasicInfoComponent from "../BasicInfoComponent.vue";
import { useRoute, useRouter } from "vue-router";
import { LocalStorage } from "quasar";
import { ref } from "vue";

const useAssents = createNamespacedHelpers("assents");

export default {
  name: "PublicItemComponent",

  components: {
    BasicInfoComponent,
  },

  props: {
    tab: {
      type: String,
      required: true,
    },
    mission: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const router = useRouter(),
      route = useRoute(),
      rewardModal = ref(false);

    const { ImgNotFound } = useAssents.useGetters(["ImgNotFound"]),
      // eslint-disable-next-line vue/no-setup-props-destructure
      { asset } = props.mission;

    const getImage = () => (asset ? asset.url : ImgNotFound);

    const redirect = (slug) => {
      LocalStorage.set("returnPublic", route.params.slugCompany);
      router.push({ name: "public.missions-show", params: { slug } });
    };

    return {
      rewardModal,
      getImage,
      redirect,
    };
  },
};
</script>

<style scoped lang="scss">
@import "src/styles/app.scss";

.card-title {
  font-size: $cardTitle;
}

.separator {
  border-left: 1px solid #ccc;
  padding-left: 20px;
}

.card-data {
  font-size: $cardData;
}

.link-color {
  color: $greenColorSite;
  text-decoration: none;
}

@media screen and (max-width: 1600px) {
  .card-title {
    font-size: $cardTitle1600;
  }

  .card-data {
    font-size: $cardData1600;
  }
}

@media screen and (max-width: 1368px) {
  .card-title {
    font-size: $cardTitle1368;
  }
  .card-data {
    font-size: $cardData1368;
  }
}

@media screen and (max-width: 900px) {
  .mobile-logo {
    width: 100px;
    margin: 10px auto 15px auto;
    display: block;
  }
  .card-title {
    font-size: $cardTitleMob;
  }
  .card-data {
    font-size: $cardDataMob;
  }
}
</style>
