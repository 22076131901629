<template>
  <div class="content-missions container">
    <public-header-component
      :tab="tab"
      @tabChange="tab = $event"
      :logo="getPartnerParentLogo"
    />

    <div class="container" :class="{ 'page-body': $q.platform.is.desktop }">
      <div class="q-pr-lg q-pl-lg q-mt-lg">
        <q-carousel
          v-model="slide"
          :autoplay="true"
          :arrows="getPublicHeader.length > 1"
          transition-prev="slide-right"
          transition-next="slide-left"
          @mouseleave="autoplay = true"
          @mouseenter="autoplay = false"
        >
          <q-carousel-slide
            v-for="(slide, i) in getPublicHeader"
            :key="i"
            :name="i"
            :img-src="slide.value"
          />
        </q-carousel>
      </div>
      <q-tab-panels class="bg-transparent" v-model="tab" animated>
        <q-tab-panel name="notEnrolled">
          <public-contents-component
            :tab="tab"
            @changeCurrent="filter($event, 'notEnrolled')"
            :last_page="getPublicMissions.last_page"
            :not_enrolled="getPublicMissions.data"
          />
        </q-tab-panel>
        <q-tab-panel name="finished">
          <public-contents-component
            :tab="tab"
            @changeCurrent="filter($event, 'finished')"
            :last_page="getPublicFinished.last_page"
            :not_enrolled="getPublicFinished.data"
          />
        </q-tab-panel>
      </q-tab-panels>
    </div>
    <footer-component />
  </div>
</template>

<script>
import PublicHeaderComponent from "./components/internal-components/PublicHeaderComponent.vue";
import PublicContentsComponent from "./components/PublicContentsComponent.vue";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import FooterComponent from "../../components/FooterComponent.vue";
import { useRoute, useRouter } from "vue-router";
import { onMounted, ref } from "vue";
import { useQuasar } from "quasar";

const { useActions, useGetters } = createNamespacedHelpers("missions"),
  partner = createNamespacedHelpers("partner");

export default {
  name: "PublicMissionPage",

  components: {
    PublicHeaderComponent,
    PublicContentsComponent,
    FooterComponent,
  },

  setup() {
    const route = useRoute(),
      router = useRouter(),
      slug = ref(route.params.slugCompany),
      tab = ref("notEnrolled"),
      q = useQuasar();

    const { getPublicMissions, getPublicFinished, getPublicHeader } =
        useGetters([
          "getPublicMissions",
          "getPublicFinished",
          "getPublicHeader",
        ]),
      {
        fetchPublicMissions,
        fetchPublicFinished,
        fetchPublicHeader,
        fetchPublicHeaderCompany,
      } = useActions([
        "fetchPublicMissions",
        "fetchPublicFinished",
        "fetchPublicHeader",
        "fetchPublicHeaderCompany",
      ]),
      { fetchPartnerBySlugAction } = partner.useActions([
        "fetchPartnerBySlugAction",
      ]),
      { getPartnerSlug, getPartnerParentLogo } = partner.useGetters([
        "getPartnerSlug",
        "getPartnerParentLogo",
      ]);

    const filter = (value, tab) => {
      const routeParams =
        route.name == "public.missions"
          ? { page: value, vigente: true }
          : { slug: slug.value, page: value, vigente: true };

      if (tab === "notEnrolled") fetchPublicMissions(routeParams);
      else {
        delete routeParams.vigente;
        fetchPublicFinished(routeParams);
      }
    };

    onMounted(async () => {
      if (route.name == "public.missions.company") {
        await fetchPartnerBySlugAction({ slug: slug.value }).catch(() =>
          router.push({ name: "404" })
        );

        fetchPublicHeaderCompany({
          slug: slug.value,
          target: `target=${
            q.platform.is.mobile
              ? "company_mission_header_mobile"
              : "company_mission_header"
          }`,
        });
      } else
        fetchPublicHeader({
          target: q.platform.is.mobile
            ? "mission_header_mobile"
            : "mission_header",
        });

      fetchPublicMissions(
        route.name == "public.missions"
          ? { vigente: true }
          : { slug: slug.value, vigente: true }
      );
      fetchPublicFinished(
        route.name == "public.missions" ? {} : { slug: slug.value }
      );
    });

    return {
      tab,
      slide: ref(1),
      getPublicMissions,
      getPublicFinished,
      getPartnerSlug,
      getPublicHeader,
      getPartnerParentLogo,
      filter,
    };
  },
};
</script>

<style scoped lang="scss">
@import "src/styles/app.scss";
.container {
  background-color: $publicBackgroundColor;
}
.page-body {
  width: $publicContainer;
  margin: auto;
}
@media only screen and (max-width: 1600px) {
  .page-body {
    width: 94%;
  }
}
.mobile {
  .page-body {
    width: $publicContainerMob;
    margin: auto;
  }
}
</style>
